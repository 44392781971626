/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CompanyProduct } from '@/interfaces/company-product.interface';
import type { CompanyProfile } from '@/interfaces/company-profile.interface';
import type { DetailedProduct, Lead, ResultText, Scan } from '@prisma/client';
import { toast } from 'vue-sonner';
import { ErrorCode } from '~/enum/error-code.enum';
import type { Language } from '~/enum/language.enum';

import { ResultTextType } from '~/enum/result-text-type.enum';
import type { SalesTextModificator } from '~/enum/sales-text-modificator.enum';
export interface ScanProfiles extends Scan {
  companyProfile?: CompanyProfile;
  targetProfile?: CompanyProfile;
  detailedProduct?: DetailedProduct;
  resultTexts?: ResultText[];
}

//todo: refactor to scan.store and move profiles to organization.store
export const useCompanyStore = defineStore('company', () => {
  const activeScan = ref<Scan>();
  const baseCompany = ref<CompanyProfile | undefined>();
  const baseProduct = ref<DetailedProduct | undefined>();
  const detailedProducts = ref<DetailedProduct[]>([]);
  const freeTextResult = ref('');
  const leadList = ref<Lead[]>([]);
  const localArchive = [];
  const ownProfiles = ref<CompanyProfile[]>([]);
  const resultTexts = ref<ResultText[]>([]);
  const revisionNew = ref<string>('');
  const scans = ref<ScanProfiles[]>([]); // todo: move to scan store
  const targetCompany = ref<CompanyProfile | undefined>();
  const targetProfiles = ref<CompanyProfile[]>([]);

  type ApiUrl =
    | 'compatibilityReason'
    | 'revision'
    | 'salesArgumentation'
    | 'salesText'
    | 'salesTextProduct'
    | 'whatWhy'
    | 'whatWhyProduct';

  const generatedTexts: Record<ApiUrl, Ref<string>> = {
    compatibilityReason: ref(''),
    revision: ref(''),
    salesArgumentation: ref(''),
    salesText: ref(''),
    salesTextProduct: ref(''),
    whatWhy: ref(''),
    whatWhyProduct: ref(''),
  };

  const { t } = useI18n({ useScope: 'local' });

  const apiMapping: Record<string, ApiUrl> = {
    salestext: 'salesText',
    'what-why': 'whatWhy',
    'what-why-product': 'whatWhyProduct',
    'salestext-product': 'salesTextProduct',
    modification: 'revision',
  };

  async function sendFreeText(prompt: string) {
    const stream = await fetch(`/api/freetext`, {
      body: JSON.stringify({
        prompt: prompt,
        activeScanId: activeScan.value?.id,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    await readStream(stream, freeTextResult);
  }

  async function createCompanyProfile(
    url: string,
    isBaseCompany: boolean,
    signal: AbortSignal,
    recaptchaToken: string,
    language: Language
  ) {
    const profile = await createProfile(
      url,
      isBaseCompany,
      signal,
      recaptchaToken,
      language
    );
    if (!profile) {
      return;
    }
    if (isBaseCompany) {
      baseCompany.value = profile;
      if (baseCompany.value) {
        baseCompany.value.url = url;
      }
      ownProfiles.value.push({
        ...profile,
        createdAt: new Date(profile.createdAt),
      });
    } else {
      targetCompany.value = profile;
      if (targetCompany.value) {
        targetCompany.value.url = url;
      }
    }
  }

  function setActiveProfile(args: {
    profile: CompanyProfile;
    isBaseProfile: boolean;
  }) {
    const { profile, isBaseProfile } = args;

    if (isBaseProfile) {
      baseProduct.value = undefined;
      baseCompany.value = profile;
      return;
    }
    targetCompany.value = profile;
  }

  async function createProfile(
    url: string,
    isBaseCompany: boolean,
    signal: AbortSignal,
    recaptchaToken: string,
    language: Language
  ) {
    const res = await $fetch<CompanyProfile>(
      `/api/scans/company?url=${url}&isTargetCompany=${!isBaseCompany}&language=${language}`,
      {
        signal,
        headers: {
          'X-Recaptcha-Token': recaptchaToken,
        },
        retry: false,
      }
    );
    return res;
  }

  async function deleteProfile(profileId: string, isOwnProfile: boolean) {
    await useFetch(`/api/profiles/${profileId}`, {
      method: 'DELETE',
      retry: false,
    });
    if (isOwnProfile) {
      const index = ownProfiles.value.findIndex((p) => p.id === profileId);
      if (index !== -1) {
        ownProfiles.value.splice(index, 1);
      }
    } else {
      const index = targetProfiles.value.findIndex((p) => p.id === profileId);
      if (index !== -1) {
        targetProfiles.value.splice(index, 1);
      }
    }
  }

  async function deleteAiGeneratedProfile(id: string) {
    const isOwnProfile = baseCompany.value?.id === id;
    if (isOwnProfile) {
      baseCompany.value = undefined;
    }
    deleteProfile(id, isOwnProfile);
  }

  async function createScan(args: {
    companyProfileId?: string;
    detailedProductId?: string;
    targetProfileId: string;
    recaptchaToken: string;
    language: Language;
    signal?: AbortSignal;
  }) {
    const { companyProfileId, targetProfileId, detailedProductId } = args;

    const scan = await $fetch<Scan>('/api/scans', {
      method: 'POST',
      body: {
        companyProfileId,
        targetProfileId,
        detailedProductId,
        language: args.language,
      },
      headers: { 'X-Recaptcha-Token': args.recaptchaToken },
      retry: false,
      signal: args.signal,
    });
    scans.value.push(scan);
    activeScan.value = scan;
  }

  async function createResultText(args: {
    name: string;
    resultTextType: ResultTextType;
    scanId: string;
    text: string;
    language: Language;
    modifications?: SalesTextModificator[];
  }) {
    const { name, resultTextType, scanId, text, language, modifications } =
      args;

    const resultText = await $fetch<ResultText>('/api/resulttexts', {
      method: 'POST',
      body: {
        name,
        resultTextType,
        scanId,
        text,
        modifications,
        language,
      },

      retry: false,
    }).catch((e) => {
      toast.error(t(e.data?.statusMessage ?? ErrorCode.GENERIC), {
        duration: 1000000,
      });
      clearError();
    });

    if (resultText) {
      resultTexts.value.push(resultText);
    }
  }

  async function createDetailedProductByUrl(url: string, language: Language) {
    try {
      const product = await $fetch<DetailedProduct>(
        `/api/scans/product-website`,
        {
          method: 'POST',
          body: {
            url,
            language,
          },
          retry: false,
        }
      );
      detailedProducts.value.push(product);
    } catch (e: any) {
      toast.error(t(e.data?.statusMessage ?? ErrorCode.GENERIC), {
        duration: 1000000,
      });
      clearError();
    }
  }

  function resetScan(args: { preserveProfiles?: boolean } = {}) {
    const { preserveProfiles } = args;
    activeScan.value = undefined;
    resultTexts.value = [];

    generatedTexts.compatibilityReason.value = '';
    generatedTexts.salesText.value = '';
    generatedTexts.salesTextProduct.value = '';
    generatedTexts.whatWhyProduct.value = '';
    generatedTexts.whatWhy.value = '';
    if (!preserveProfiles) {
      targetCompany.value = undefined;
    }
  }

  function setDetailedProduct(detailedProduct: DetailedProduct) {
    if (baseCompany.value) {
      baseCompany.value = undefined;
    }
    baseProduct.value = detailedProduct;
  }

  async function createDetailedProductByFile(
    downloadUrl: string,
    url: string,
    fileName: string,
    language: Language
  ) {
    if (!url || !fileName) {
      return;
    }
    try {
      await $fetch<DetailedProduct>(`/api/scans/product-file`, {
        method: 'POST',
        body: { url, fileName, downloadUrl, language },
        retry: false,
      });
    } catch (e: any) {
      toast.error(t(e.data?.statusMessage ?? ErrorCode.GENERIC), {
        duration: 1000000,
      });
      clearError();
    }
  }

  async function getDetailedProducts() {
    const products = await $fetch<DetailedProduct[]>(
      `/api/detailed-products/`,
      { retry: false }
    );

    detailedProducts.value = products.map((p) => ({
      ...p,
      createdAt: new Date(p.createdAt),
    }));
  }

  async function deleteDetailedProduct(id: string) {
    await $fetch<{ success: boolean }>(`/api/detailed-products/${id}`, {
      method: 'DELETE',
      retry: false,
    });

    const index = detailedProducts.value.findIndex((p) => p.id === id);
    if (index !== -1) {
      detailedProducts.value.splice(index, 1);
    }
  }

  async function deleteScan(id: string) {
    await $fetch<{ success: boolean }>(`/api/scans/${id}`, {
      method: 'DELETE',
      retry: false,
    });

    const index = scans.value.findIndex((p) => p.id === id);
    if (index !== -1) {
      scans.value.splice(index, 1);
    }
  }

  async function generateTextsAsStreams(
    api:
      | 'salestext'
      | 'salestext-product'
      | 'what-why'
      | 'what-why-product'
      | 'modification',
    recaptchaToken: string,
    signal: AbortSignal,
    language?: Language,
    modificators?: SalesTextModificator[]
  ) {
    const stream = await fetch(`/api/generators/${api}`, {
      body: JSON.stringify(getRequestBody(api, modificators, language)),
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': recaptchaToken,
      },
      method: 'POST',
      signal,
    });

    if (!stream.ok) {
      throw { status: stream.status, statusMessage: stream.statusText };
    }

    const data =
      api === 'modification' ? revisionNew : generatedTexts[apiMapping[api]];

    await readStream(stream, data);
    addToArchive();
  }

  function getRequestBody(
    api: string,
    modificators?: SalesTextModificator[],
    language?: Language
  ) {
    if (api === 'salestext') {
      return {
        whatWhyId: resultTexts.value[resultTexts.value.length - 1].id,
        targetCompanyId: targetCompany.value?.id,
        baseCompanyId: baseCompany.value?.id,
        language,
      };
    }
    if (api === 'what-why-product') {
      return {
        targetCompanyId: targetCompany.value?.id,
        detailedProductId: baseProduct.value?.id,
        language,
      };
    }
    if (api === 'salestext-product') {
      return {
        whatWhyId: resultTexts.value[resultTexts.value.length - 1].id,
        targetCompanyId: targetCompany.value?.id,
        detailedProductId: baseProduct.value?.id,
        language,
      };
    }
    if (api === 'modification') {
      return {
        resultTextId: resultTexts.value.find(
          (r) => r.resultTextType === ResultTextType.SALESTEXT
        )?.id,
        modificators,
        language,
      };
    }

    //WHATWHY
    return {
      baseCompanyId: baseCompany.value?.id,
      targetCompanyId: targetCompany.value?.id,
      language,
    };
  }

  async function getScans() {
    const res = await $fetch<Scan[]>(`/api/scans`, { retry: false });
    scans.value = res;
  }

  async function getOwnProfiles() {
    if (ownProfiles.value.length) return;

    const res = await $fetch<CompanyProfile[]>(`/api/profiles`, {
      retry: false,
    });

    ownProfiles.value = res
      .map((p) => ({
        ...p,
        createdAt: new Date(p.createdAt),
      }))
      .filter((p) => !p.aiGenerated);
  }

  async function createProductByUrl(url: string, crawlSubPages = false) {
    const res = await $fetch<CompanyProduct>('/api/products/', {
      method: 'POST',
      body: JSON.stringify({
        url,
        crawlSubPages,
      }),
      retry: false,
    });
    baseCompany.value?.products?.push(res);
  }

  function addToArchive() {
    localArchive.push({ baseCompany, targetCompany, generatedTexts });
  }

  async function addLeadToLeadLst(id: string) {
    const { leads } = await $fetch<{ leads: Lead[] }>(`/api/linkedIn/leads`, {
      method: 'POST',
      body: {
        id,
      },
      retry: false,
    });
    leadList.value?.push(...leads);
  }

  function clear() {
    activeScan.value = undefined;
    baseCompany.value = undefined;
    baseProduct.value = undefined;
    detailedProducts.value = [];
    ownProfiles.value = [];
    resultTexts.value = [];
    scans.value = [];
    targetCompany.value = undefined;
    targetProfiles.value = [];
    freeTextResult.value = '';
  }
  return {
    activeScan,
    addLeadToLeadLst,
    baseCompany,
    baseProduct,
    clear,
    createCompanyProfile,
    createDetailedProductByFile,
    createDetailedProductByUrl,
    createProductByUrl,
    createResultText,
    createScan,
    deleteDetailedProduct,
    deleteProfile,
    deleteScan,
    detailedProducts,
    freeTextResult,
    generateTextsAsStreams,
    generatedTexts,
    getDetailedProducts,
    getOwnProfiles,
    getScans,
    ownProfiles,
    resetScan,
    resultTexts,
    revisionNew,
    scans,
    sendFreeText,
    setActiveProfile,
    setDetailedProduct,
    targetCompany,
    targetProfiles,
    deleteAiGeneratedProfile,
  };
});
