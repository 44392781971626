import { ErrorCode } from '~/enum/error-code.enum';

export default async function(
  response: Response,
  data?: Ref<string>,
  cb?: (value: string) => void
)  {
  if (!response.body) {
    throw new Error('Response body is not available.');
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');

  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      if (typeof data?.value === 'string') {
        data.value += decoder.decode(value, { stream: true });
      }
      if (cb) {
        const val = decoder.decode(value, { stream: true });
        cb(val);
      }
    }
  } catch (e) {
    console.error('Error while reading the stream:', e);
    throw createError({
      statusCode: 400,
      statusMessage: ErrorCode.GENERIC,
    });
  } finally {
    reader.releaseLock();
  }

  return true;
};
